import axios, { AxiosError } from 'axios';
import appConfig from '~/app.config';
import axiosRetry from 'axios-retry';

// const baseUrl = process.env.NODE_ENV == 'development' ? 'https://apitest.bestfaceswap.ai' : 'https://api.bestfaceswap.ai'
const baseUrl = appConfig.apiBase
const request = axios.create({
    baseURL: baseUrl
});

request.interceptors.request.use(function (request) {
    const { token } = useAuthStore()
    if (token) {
        request.headers.Authorization = `Bearer ${token}`
    }
    return request;
}, function (error) {
    return Promise.reject(error);
});
request.interceptors.response.use(function (response) {
    // 统一处理响应数据
    if (response.data && response.data.code === 200) {
        return response.data.data; // 假设业务数据在 data.data
    } else {
        // 处理其他非 200 状态码
        return Promise.reject(response.data.message);
    }
}, async function (error: AxiosError<{
    message: string
}>) {
    const router = useRouter()
    const auth = useAuthStore()
    const snackbarStore = useSnackbarStore();

    // 处理请求超时错误
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        // snackbarStore.showError('请求超时，请稍后重试');
        return Promise.reject(error);
    }

    // 处理网络错误（比如断网）
    if (!error.response) {
        // snackbarStore.showError('网络异常，请检查网络连接');
        return Promise.reject(error);
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
        snackbarStore.showError(error.response?.data?.message || error.response || error);
        await auth.logout(`/login/google?redirect=${router.currentRoute.value.fullPath}`)
    } else {
        // snackbarStore.showError(error.response?.data?.message || 'Unknown error');
        console.log(error);
    }
    return Promise.reject(error);
});

// 配置请求重试机制
axiosRetry(request, {
    shouldResetTimeout: true,
    retries: 5,
    retryDelay: (retryCount: number) => {
        return retryCount * 300;
    },
    retryCondition: (error) => {
        // 不重试的情况：
        // 1. 401/403 认证错误
        // 2. 404 资源不存在
        // 3. 429 请求限制
        const skipStatuses = [401, 403, 404, 429];
        if (error.response && skipStatuses.includes(error.response.status)) {
            return false;
        }

        // 重试条件：
        // 1. 网络错误
        // 2. 幂等请求
        // 3. 服务器错误(500+)
        // 4. 请求超时
        return (
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            error.response?.status >= 500 ||
            error.code === 'ECONNABORTED'
        );
    },
});

export default request;

<script setup lang="ts">
const { userInfo } = useCommStore();
const router = useRouter();
const localePath = useLocalePath();
</script>
<template>
    <div id="uploads-and-creations" class="w-full bg-neutral-900 px-9 py-6 min-w-[368px]  rounded-xl mt-4 font-bold"
        style="box-shadow: 0px 2px 5px 0px #00000040;">
        <div class="space-y-6">
            <v-row @click="router.push(localePath('/my/uploads'))"
                class="bg-neutral-800 rounded-lg p-2 cursor-pointer  bdo !mt-0">
                <v-col class="label flex items-center" align="start">
                    <v-icon icon="mdi-cloud-upload mr-1"></v-icon>
                    <span>My Uploads</span>
                </v-col>
                <v-col align="end">
                    <span class="text-[#F06906]">{{ userInfo.counter_materials || 0 }}</span>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </v-col>
            </v-row>
            <v-row @click="router.push(localePath('/my/creations'))"
                class="bg-neutral-800 rounded-lg p-2 cursor-pointer  bdo">
                <v-col class="label flex items-center" align="start">
                    <v-icon icon="mdi-star-check mr-1"></v-icon>
                    <span class="text-nowrap whitespace-pre">My Creations</span>
                </v-col>
                <v-col align="end">
                    <span>{{ userInfo.counter_orders || 0 }}</span>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<style>
.bdo {
    border: 1px solid #2A2C32;
}

.bdo:hover {
    border: 1px solid #F06906 !important;
}
</style>
export default defineAppConfig({
  apiBase: import.meta.env.VITE_PUBLIC_API_BASE,
  videoExt: [
      { ext: 'mp4', mime: 'video/mp4' },
      { ext: 'mov', mime: 'video/quicktime' },
      // { ext: 'mkv', mime: 'video/x-matroska' },
      { ext: 'mpeg', mime: 'video/mpeg' },
      { ext: 'webm', mime: 'video/webm' },
  ],
  imageExt: [
      { ext: 'jpg', mime: 'image/jpeg' },
      { ext: 'jpeg', mime: 'image/jpeg' },
      { ext: 'png', mime: 'image/png' },
      { ext: 'gif', mime: 'image/gif' },
  ],
});
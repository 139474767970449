
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "8e7763a7-9691-4e35-a4e6-a0a8a17cc09d"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)

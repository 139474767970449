<template>
  <div>
    <NewHeader></NewHeader>
    <div id="banner-top" class="w-full pt-60 sm:pb-20 pb-12">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="w-full lg:py-40 sm:py-20 py-12 px-6 bg-neutral-900 rounded-3xl flex-col justify-center items-center gap-1 inline-flex">
          <div class="justify-center items-center gap-2.5 inline-flex">
            <h2 class="text-right text-white text-9xl font-semibold font-manrope leading-tight">4</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
              <path d="M26.25 37.5L29.7949 39.8632C31.0545 40.703 32.6955 40.703 33.9551 39.8632L37.5 37.5M56.25 60L50.625 56.25C47.2188 53.9792 42.7812 53.9792 39.375 56.25L33.75 60M52.5 37.5L56.0449 39.8632C57.3045 40.703 58.9455 40.703 60.2051 39.8632L63.75 37.5M78.75 45C78.75 63.6396 63.6396 78.75 45 78.75C26.3604 78.75 11.25 63.6396 11.25 45C11.25 26.3604 26.3604 11.25 45 11.25C63.6396 11.25 78.75 26.3604 78.75 45Z" stroke="white" stroke-width="7" stroke-linecap="round" />
            </svg>
            <h2 class="text-white text-9xl font-semibold font-manrope leading-tight">4</h2>
          </div>
          <div class="w-full flex-col justify-start items-center gap-10 flex">
            <div class="w-full flex-col justify-start items-center gap-1.5 flex">
              <h3 class="text-center text-white text-2xl font-bold font-manrope leading-9">Oh No... We Lost
                This Page</h3>
              <p class="text-center text-white/50 text-base font-normal leading-relaxed">We suggest you go to
                homepage while we fixing the problem.</p>
            </div>
            <a href="/index.html" class="sm:w-fit w-full">
              <button class="sm:w-fit w-full px-4 rounded-full py-4 bg-orange-600 hover:bg-orange-700 transition-all duration-700 ease-in-out shadow-xs justify-center items-center flex">
                <span class="px-1.5 text-white text-sm font-medium leading-6">Back to Home</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <NewFooter></NewFooter>
  </div>

</template>

<script lang="ts" setup>
import NewHeader from './components/NewHeader.vue';
import NewFooter from './components/NewFooter.vue';
import type { NuxtError } from '#app';

// const error = useError();

const { $logError }: any = useNuxtApp();

const props = defineProps({
  error: Object as () => NuxtError
});

$logError(props.error, `Runtime Error: ${props.error.message}`)

</script>

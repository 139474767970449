import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as creation_detailfjZVoQNQ2uMeta } from "/app/pages/creation_detail.vue?macro=true";
import { default as face_45swap_45photo7jj6KrJPgEMeta } from "/app/pages/face-swap-photo.vue?macro=true";
import { default as face_45swap_45videojLWojGLkrOMeta } from "/app/pages/face-swap-video.vue?macro=true";
import { default as face_45swapYghdX64zhdMeta } from "/app/pages/face-swap.vue?macro=true";
import { default as index_45oldY0TQGD5rVVMeta } from "/app/pages/index-old.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_91site_93_93knQhjjWy9qMeta } from "/app/pages/login/[[site]].vue?macro=true";
import { default as accountTTkNRzIkIQMeta } from "/app/pages/my/account.vue?macro=true";
import { default as creationsL3eB1hwRtgMeta } from "/app/pages/my/creations.vue?macro=true";
import { default as creditsJW2Z4wxIGFMeta } from "/app/pages/my/credits.vue?macro=true";
import { default as facesX18egbcj4DMeta } from "/app/pages/my/faces.vue?macro=true";
import { default as uploadshmpHK1MUa0Meta } from "/app/pages/my/uploads.vue?macro=true";
import { default as myas0rhe6oGEMeta } from "/app/pages/my.vue?macro=true";
import { default as password_45changePUi0tm8CZKMeta } from "/app/pages/password-change.vue?macro=true";
import { default as password_45reset39IAnjWvPiMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as payment_45failedfyVjkMyN2QMeta } from "/app/pages/payment-failed.vue?macro=true";
import { default as payment_45successxSyLRJqw0cMeta } from "/app/pages/payment-success.vue?macro=true";
import { default as policyhDmBrXN4qOMeta } from "/app/pages/policy.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
export default [
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___en",
    path: contactV6oPJjs9RyMeta?.path ?? "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: creation_detailfjZVoQNQ2uMeta?.name ?? "creation_detail___en",
    path: creation_detailfjZVoQNQ2uMeta?.path ?? "/creation_detail",
    meta: creation_detailfjZVoQNQ2uMeta || {},
    alias: creation_detailfjZVoQNQ2uMeta?.alias || [],
    redirect: creation_detailfjZVoQNQ2uMeta?.redirect,
    component: () => import("/app/pages/creation_detail.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45photo7jj6KrJPgEMeta?.name ?? "face-swap-photo___en",
    path: face_45swap_45photo7jj6KrJPgEMeta?.path ?? "/face-swap-photo",
    meta: face_45swap_45photo7jj6KrJPgEMeta || {},
    alias: face_45swap_45photo7jj6KrJPgEMeta?.alias || [],
    redirect: face_45swap_45photo7jj6KrJPgEMeta?.redirect,
    component: () => import("/app/pages/face-swap-photo.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45videojLWojGLkrOMeta?.name ?? "face-swap-video___en",
    path: face_45swap_45videojLWojGLkrOMeta?.path ?? "/face-swap-video",
    meta: face_45swap_45videojLWojGLkrOMeta || {},
    alias: face_45swap_45videojLWojGLkrOMeta?.alias || [],
    redirect: face_45swap_45videojLWojGLkrOMeta?.redirect,
    component: () => import("/app/pages/face-swap-video.vue").then(m => m.default || m)
  },
  {
    name: face_45swapYghdX64zhdMeta?.name ?? "face-swap___en",
    path: face_45swapYghdX64zhdMeta?.path ?? "/face-swap",
    meta: face_45swapYghdX64zhdMeta || {},
    alias: face_45swapYghdX64zhdMeta?.alias || [],
    redirect: face_45swapYghdX64zhdMeta?.redirect,
    component: () => import("/app/pages/face-swap.vue").then(m => m.default || m)
  },
  {
    name: index_45oldY0TQGD5rVVMeta?.name ?? "index-old___en",
    path: index_45oldY0TQGD5rVVMeta?.path ?? "/index-old",
    meta: index_45oldY0TQGD5rVVMeta || {},
    alias: index_45oldY0TQGD5rVVMeta?.alias || [],
    redirect: index_45oldY0TQGD5rVVMeta?.redirect,
    component: () => import("/app/pages/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91site_93_93knQhjjWy9qMeta?.name ?? "login-site___en",
    path: _91_91site_93_93knQhjjWy9qMeta?.path ?? "/login/:site?",
    meta: _91_91site_93_93knQhjjWy9qMeta || {},
    alias: _91_91site_93_93knQhjjWy9qMeta?.alias || [],
    redirect: _91_91site_93_93knQhjjWy9qMeta?.redirect,
    component: () => import("/app/pages/login/[[site]].vue").then(m => m.default || m)
  },
  {
    name: myas0rhe6oGEMeta?.name ?? "my___en",
    path: myas0rhe6oGEMeta?.path ?? "/my",
    meta: myas0rhe6oGEMeta || {},
    alias: myas0rhe6oGEMeta?.alias || [],
    redirect: myas0rhe6oGEMeta?.redirect,
    component: () => import("/app/pages/my.vue").then(m => m.default || m),
    children: [
  {
    name: accountTTkNRzIkIQMeta?.name ?? "my-account___en",
    path: accountTTkNRzIkIQMeta?.path ?? "account",
    meta: accountTTkNRzIkIQMeta || {},
    alias: accountTTkNRzIkIQMeta?.alias || [],
    redirect: accountTTkNRzIkIQMeta?.redirect,
    component: () => import("/app/pages/my/account.vue").then(m => m.default || m)
  },
  {
    name: creationsL3eB1hwRtgMeta?.name ?? "my-creations___en",
    path: creationsL3eB1hwRtgMeta?.path ?? "creations",
    meta: creationsL3eB1hwRtgMeta || {},
    alias: creationsL3eB1hwRtgMeta?.alias || [],
    redirect: creationsL3eB1hwRtgMeta?.redirect,
    component: () => import("/app/pages/my/creations.vue").then(m => m.default || m)
  },
  {
    name: creditsJW2Z4wxIGFMeta?.name ?? "my-credits___en",
    path: creditsJW2Z4wxIGFMeta?.path ?? "credits",
    meta: creditsJW2Z4wxIGFMeta || {},
    alias: creditsJW2Z4wxIGFMeta?.alias || [],
    redirect: creditsJW2Z4wxIGFMeta?.redirect,
    component: () => import("/app/pages/my/credits.vue").then(m => m.default || m)
  },
  {
    name: facesX18egbcj4DMeta?.name ?? "my-faces___en",
    path: facesX18egbcj4DMeta?.path ?? "faces",
    meta: facesX18egbcj4DMeta || {},
    alias: facesX18egbcj4DMeta?.alias || [],
    redirect: facesX18egbcj4DMeta?.redirect,
    component: () => import("/app/pages/my/faces.vue").then(m => m.default || m)
  },
  {
    name: uploadshmpHK1MUa0Meta?.name ?? "my-uploads___en",
    path: uploadshmpHK1MUa0Meta?.path ?? "uploads",
    meta: uploadshmpHK1MUa0Meta || {},
    alias: uploadshmpHK1MUa0Meta?.alias || [],
    redirect: uploadshmpHK1MUa0Meta?.redirect,
    component: () => import("/app/pages/my/uploads.vue").then(m => m.default || m)
  }
]
  },
  {
    name: password_45changePUi0tm8CZKMeta?.name ?? "password-change___en",
    path: password_45changePUi0tm8CZKMeta?.path ?? "/password-change",
    meta: password_45changePUi0tm8CZKMeta || {},
    alias: password_45changePUi0tm8CZKMeta?.alias || [],
    redirect: password_45changePUi0tm8CZKMeta?.redirect,
    component: () => import("/app/pages/password-change.vue").then(m => m.default || m)
  },
  {
    name: password_45reset39IAnjWvPiMeta?.name ?? "password-reset___en",
    path: password_45reset39IAnjWvPiMeta?.path ?? "/password-reset",
    meta: password_45reset39IAnjWvPiMeta || {},
    alias: password_45reset39IAnjWvPiMeta?.alias || [],
    redirect: password_45reset39IAnjWvPiMeta?.redirect,
    component: () => import("/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedfyVjkMyN2QMeta?.name ?? "payment-failed___en",
    path: payment_45failedfyVjkMyN2QMeta?.path ?? "/payment-failed",
    meta: payment_45failedfyVjkMyN2QMeta || {},
    alias: payment_45failedfyVjkMyN2QMeta?.alias || [],
    redirect: payment_45failedfyVjkMyN2QMeta?.redirect,
    component: () => import("/app/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: payment_45successxSyLRJqw0cMeta?.name ?? "payment-success___en",
    path: payment_45successxSyLRJqw0cMeta?.path ?? "/payment-success",
    meta: payment_45successxSyLRJqw0cMeta || {},
    alias: payment_45successxSyLRJqw0cMeta?.alias || [],
    redirect: payment_45successxSyLRJqw0cMeta?.redirect,
    component: () => import("/app/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: policyhDmBrXN4qOMeta?.name ?? "policy___en",
    path: policyhDmBrXN4qOMeta?.path ?? "/policy",
    meta: policyhDmBrXN4qOMeta || {},
    alias: policyhDmBrXN4qOMeta?.alias || [],
    redirect: policyhDmBrXN4qOMeta?.redirect,
    component: () => import("/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: pricingpTmAIboEh0Meta?.name ?? "pricing___en",
    path: pricingpTmAIboEh0Meta?.path ?? "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    alias: pricingpTmAIboEh0Meta?.alias || [],
    redirect: pricingpTmAIboEh0Meta?.redirect,
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: signupezBbZGXKU6Meta?.name ?? "signup___en",
    path: signupezBbZGXKU6Meta?.path ?? "/signup",
    meta: signupezBbZGXKU6Meta || {},
    alias: signupezBbZGXKU6Meta?.alias || [],
    redirect: signupezBbZGXKU6Meta?.redirect,
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: termsy18ZESLqu6Meta?.name ?? "terms___en",
    path: termsy18ZESLqu6Meta?.path ?? "/terms",
    meta: termsy18ZESLqu6Meta || {},
    alias: termsy18ZESLqu6Meta?.alias || [],
    redirect: termsy18ZESLqu6Meta?.redirect,
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  }
]